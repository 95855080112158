<template>
  <div class="canvas-box">
    <div class="canvas">
      <!-- 游戏开始场景 -->
      <img v-show="startindex" src="@/assets/img/images/coverBg.jpg" alt="">
      <img v-show="startindex" class="start-size-bg" src="@/assets/img/images/LogoLine.png" alt="">
      <span v-show="startindex" class="start-size" @click="gameStart">点击开始游戏</span>
      <!-- 进入游戏场景 -->
      <img v-show="startBg" class="game-bg" :class="{'moveStartBg':startBg === true}" src="@/assets/img/images/background1.jpg" alt="">
      <img class="careimg1" v-show="coreshow1" src="@/assets/img/images/loading/loading_00.png" alt="">
      <img class="careimg2" v-show="coreshow2" src="@/assets/img/images/loading/loading_01.png" alt="">
      <img class="careimg3" v-show="coreshow3" src="@/assets/img/images/loading/loading_02.png" alt="">
      <!-- 退出游戏按钮 -->
      <img class="outgameimg" v-show="cars" src="@/assets/img/images/button.png" alt="">
      <span class="outgame" v-show="cars" @click="$lv.go('/game/index')">退出游戏</span>
      <!-- 小推车 -->
      <ul v-show="cars" class="littleCar">
        <li v-for="item in car" :key="item.id"><img :src="item" alt=""></li>
      </ul>
      <!-- 植物选择头像 -->
      <ul v-show="cars" class="zhiWuimg">
        <li v-for="(cord, index) in zhiWucard" :key="index" @click="choicecord(index)">
          <img :src="cord.img" alt="">
          <span>{{cord.price}}</span>
        </li>
      </ul>
      <!-- 植物上场模块 -->
      <ul class="zhiWuopbg" v-show="cars">
        <li v-for="(upcord, index) in zhiWuopbg" :key="index" @click="downzhiWu(index)">
          <img :src="upcord.img" alt="">
        </li>
      </ul>
      <!-- 阳光 -->
      <img class="glowbg" v-show="cars" src="@/assets/img/images/sunback.png" alt="">
      <div class="glow" v-show="cars">{{glownum}}</div>
      <!-- 僵尸 -->
      <ul class="dealbody">
        <li v-for="deals in dealbody" :key="deals.id"><img :class="{'movebody': deals.move === true}" :src="deals.img" alt=""></li>
      </ul>
    </div>
  </div>
</template>
<script>
import util from '@/util'
export default {
  name: 'zhiWu',
  data () {
    return {
      startindex: true, // 游戏初始页面
      startBg: false, // 进入游戏页面
      car: [require('@/assets/img/images/car.png'), require('@/assets/img/images/car.png'), require('@/assets/img/images/car.png'), require('@/assets/img/images/car.png'), require('@/assets/img/images/car.png')],
      zhiWucard: [
        {
          img0: require('@/assets/img/images/cards/plants/SunFlower.png'),
          img: require('@/assets/img/images/cards/plants/SunFlowerG.png'),
          price: 50,
          img1: require('@/assets/img/images/cards/plants/SunFlowerG.png')
        }, {
          img0: require('@/assets/img/images/cards/plants/Peashooter.png'),
          img: require('@/assets/img/images/cards/plants/PeashooterG.png'),
          price: 100,
          img1: require('@/assets/img/images/cards/plants/PeashooterG.png')
        }, {
          img0: require('@/assets/img/images/cards/plants/WallNut.png'),
          img: require('@/assets/img/images/cards/plants/WallNutG.png'),
          price: 50,
          img1: require('@/assets/img/images/cards/plants/WallNutG.png')
        }, {
          img0: require('@/assets/img/images/cards/plants/Repeater.png'),
          img: require('@/assets/img/images/cards/plants/RepeaterG.png'),
          price: 150,
          img1: require('@/assets/img/images/cards/plants/RepeaterG.png')
        }, {
          img0: require('@/assets/img/images/cards/plants/GatlingPea.png'),
          img: require('@/assets/img/images/cards/plants/GatlingPeaG.png'),
          price: 200,
          img1: require('@/assets/img/images/cards/plants/GatlingPeaG.png')
        }, {
          img0: require('@/assets/img/images/cards/plants/Chomper.png'),
          img: require('@/assets/img/images/cards/plants/ChomperG.png'),
          price: 200,
          img1: require('@/assets/img/images/cards/plants/ChomperG.png')
        }, {
          img0: require('@/assets/img/images/cards/plants/CherryBomb.png'),
          img: require('@/assets/img/images/cards/plants/CherryBombG.png'),
          price: 250,
          img1: require('@/assets/img/images/cards/plants/CherryBombG.png')
        }
      ],
      zhiWuopbg: [
        {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }, {
          img: '',
          blood: null
        }
      ],
      zhiWubody: [
        {
          img: require('@/assets/img/images/plants/sunflower/idle/sunflowerD.gif'),
          price: 50,
          blood: 50
        }, {
          img: require('@/assets/img/images/plants/peashooter/idle/peashooter.gif'),
          price: 100,
          blood: 100
        }, {
          img: require('@/assets/img/images/plants/wallnut/idleH/wallnutH.gif'),
          price: 50,
          blood: 500
        }, {
          img: require('@/assets/img/images/plants/repeater/idle/repeaterD.gif'),
          price: 150,
          blood: 100
        }, {
          img: require('@/assets/img/images/plants/gatlingpea/idle/gatlingpeaD.gif'),
          price: 200,
          blood: 100
        }, {
          img: require('@/assets/img/images/plants/chomper/idle/chomperD.gif'),
          price: 200,
          blood: 100
        }, {
          img: require('@/assets/img/images/plants/cherrybomb/idle/cherrybombD.gif'),
          price: 250,
          blood: 100
        }
      ],
      dealbody: [ // 僵尸
        {
          img: require('@/assets/img/images/zombies/run/zombiesR.gif'),
          blood: 100,
          move: false
        }, {
          img: require('@/assets/img/images/zombies/run/zombiesR.gif'),
          blood: null,
          move: false
        }, {
          img: require('@/assets/img/images/zombies/run/zombiesR.gif'),
          blood: null,
          move: false
        }, {
          img: require('@/assets/img/images/zombies/run/zombiesR.gif'),
          blood: null,
          move: false
        }, {
          img: require('@/assets/img/images/zombies/run/zombiesR.gif'),
          blood: null,
          move: false
        }, {
          img: require('@/assets/img/images/zombies/run/zombiesR.gif'),
          blood: null,
          move: false
        }, {
          img: require('@/assets/img/images/zombies/run/zombiesR.gif'),
          blood: null,
          move: false
        }, {
          img: require('@/assets/img/images/zombies/run/zombiesR.gif'),
          blood: null,
          move: false
        }, {
          img: require('@/assets/img/images/zombies/run/zombiesR.gif'),
          blood: null,
          move: false
        }, {
          img: require('@/assets/img/images/zombies/run/zombiesR.gif'),
          blood: null,
          move: false
        }, {
          img: require('@/assets/img/images/zombies/run/zombiesR.gif'),
          blood: null,
          move: false
        }, {
          img: require('@/assets/img/images/zombies/run/zombiesR.gif'),
          blood: null,
          move: false
        }, {
          img: require('@/assets/img/images/zombies/run/zombiesR.gif'),
          blood: null,
          move: false
        }, {
          img: require('@/assets/img/images/zombies/run/zombiesR.gif'),
          blood: null,
          move: false
        }, {
          img: require('@/assets/img/images/zombies/run/zombiesR.gif'),
          blood: null,
          move: false
        }
      ],
      cars: false, // 控制小推车显示
      coreshow1: false,
      coreshow2: false,
      coreshow3: false,
      glownum: 175, // 初始阳光数量
      lucyzhiWu: {
        img: '',
        blood: null,
        price: null
      }, // 被选中的植物
      arrs: [] // 获得随机数组
    }
  },
  methods: {
    gameStart () {
      this.startindex = false
      this.startBg = true
      setTimeout(this.showcar, 3000) // 场景动画后显示小推车
      setTimeout(this.coreshowf, 3100)
      setTimeout(this.coreoutf, 3500)
      setTimeout(this.coreshows, 3800)
      setTimeout(this.coreouts, 4200)
      setTimeout(this.coreshowt, 4300)
      setTimeout(this.coreoutt, 5000)
      this.glownumplus()
      setInterval(this.glownumplus, 12000) // 每12秒阳光自动加25
      setInterval(this.movedealbodys, 10000) // 10秒出现一只僵尸
    },
    movedealbodys () { // 随机添加僵尸
      this.arrs = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15']
      this.arrs.sort(util.randomsort)
      this.dealbody[this.arrs[0]].move = true
      // this.dealbody[this.arrs[0]].img = require('@/assets/img/images/zombies/run/zombiesR.gif')
      // this.dealbody[this.arrs[0]].blood = 100
    },
    choicecord (index) { // 选择植物
      if (this.zhiWubody[index].price <= this.glownum) {
        this.lucyzhiWu = this.zhiWubody[index]
      }
    },
    downzhiWu (index) { // 种下植物
      if (this.lucyzhiWu.blood !== null && this.zhiWuopbg[index].blood === null) {
        this.zhiWuopbg[index] = this.lucyzhiWu
        this.glownum = this.glownum - this.lucyzhiWu.price
        this.lucyzhiWu = null
      } else {}
      for (var i = 0; i < this.zhiWucard.length; i++) {
        if (this.zhiWucard[i].price <= this.glownum) {
          this.zhiWucard[i].img = this.zhiWucard[i].img0
        } else if (this.zhiWucard[i].price > this.glownum) {
          this.zhiWucard[i].img = this.zhiWucard[i].img1
        }
      }
    },
    coreshowf () {
      this.coreshow1 = true
    },
    coreoutf () {
      this.coreshow1 = false
    },
    coreshows () {
      this.coreshow2 = true
    },
    coreouts () {
      this.coreshow2 = false
    },
    coreshowt () {
      this.coreshow3 = true
    },
    coreoutt () {
      this.coreshow3 = false
    },
    showcar () { // 控制小推车显示
      this.cars = true
    },
    glownumplus () {
      this.glownum += 25
      for (var i = 0; i < this.zhiWucard.length; i++) {
        if (this.zhiWucard[i].price <= this.glownum) {
          this.zhiWucard[i].img = this.zhiWucard[i].img0
        } else if (this.zhiWucard[i].price > this.glownum) {
          this.zhiWucard[i].img = this.zhiWucard[i].img1
        }
      }
    },
    createdealbody () {
      // const dbody = this.$createElement
      // dbody('li', { class: 'dealbodys' }, [
      //   dbody('img', { src: '@/assets/img/images/zombies/run/zombiesR.gif' }, {
      //   })
      // ])
    }
  },
  mounted () {
    this.createdealbody()
  },
  computed: {
  }
}
</script>
<style lang="stylus">
*
  box-sizing: border-box;
ul,li
  list-style none
.canvas-box
  display: block;
  width: 900px;
  height: 600px;
  margin: 0;
  overflow: hidden;
  border: 1px solid black;
  border-top: none;
  font-family: '微软雅黑 Regular','微软雅黑';
  color: #333;
  .canvas
    width 1400px
    height 600px
    display: block;
    // margin-left: -120px;
    border: 1px solid black;
    background: #000;
    position relative
    .start-size-bg
      position absolute
      bottom 15px
      left 240px
    .start-size
      position absolute
      bottom 15px
      left 240px
      color: #FF0;
      font-size: 18px;
      height: 69px;
      width: 321px;
      line-height: 80px;
      text-align: center;
      cursor: pointer;
      display block
    .game-bg // 游戏主战场背景
      position absolute
      top 0
      left -120px
    .careimg1
      position absolute
      top 200px
      left 380px
    .careimg2
      position absolute
      top 200px
      left 380px
    .careimg3
      position absolute
      top 200px
      left 380px
    .moveStartBg
      animation movebg 3s ease-in-out
      animation-direction alternate // 入场动画
    @keyframes movebg
      0%
        left 0px
      40%
        left -250px
      60%
        left -250px
      100%
        left -120px
    .littleCar
      height 500px
      width 100px
      position absolute
      left 50px
      top 80px
      li
        width 100px
        height 100px
        z-index 9
    .zhiWuimg // 植物卡片
      height 100%
      width 100px
      z-index 20
      position absolute
      top 0
      left 0
      li
        width 100px
        height 60px
        position relative
        cursor: pointer;
        span
          position absolute
          top 40px
          left 60px
          width 40px
          height 20px
          font-size 16px
    .glowbg // 阳光
      position absolute
      top 0
      left 100px
    .glow
      position absolute
      top 2px
      left 140px
      width 72px
      text-align center
      height 30px
      font-size 23px
      font-weight 400
    .outgameimg
      position absolute
      top 0
      left 778px
    .outgame
      position absolute
      top 0
      left 778px
      font-size 18px
      color #1AAD2A
      line-height 35px
      font-weight 600
      width 110px
      text-align center
      cursor: pointer;
    .zhiWuopbg
      width 730px
      height 500px
      position absolute
      top 80px
      left 130px
      li
        height 100px
        width 81px
        float left
    .dealbody
      width 180px
      height 500px
      position absolute
      top 10px
      right 300px
      li
        height 100px
        width 60px
        float left
        position relative
        img
          z-index 10
          position absolute
          top 0
          left 0
        .movebody
          transform translate( -1000px,0)
          transition linear 100s
          // transition-duration: 100s;
          // -webkit-transition-duration: 100s;
</style>
